import {
  isNotEmptyArray,
  isNotEmptyObject,
} from '@next-app/components/shared/ProductDetails/helper';
import {
  Badge,
  PDPAllSKUsInfo,
  PDPChildSKUInfo,
  PDPPriceInfo,
  PDPProductInfoContent,
  Status,
} from '@next-app/interface/PDP/PDPProductInfo';
import {
  getFormattedPrice,
  isNotNullOrUndefined,
  populateTemplateString,
  refetch,
} from './common-utils';
import urlMapping from '@next-app/config/urlMapping';
import { makeFetchCall } from './fetch-data';
import { SelectOption } from '@next-app/interface/Common';
import {
  AddEGiftCardToCart,
  AddNonGiftItemToCart,
  AddPhysicalGiftCardToCart,
  AddToCartResponse,
} from '@next-app/interface/PDP/PDPAddToCart';
import { ShoppingListResponse } from '../interface/PDP/PDPAddToList';
import { PayloadShareProduct } from '@next-app/interface/PDP/PDPProductShare';
import configuration from '@next-app/config/configurations';

export const checkPhysicalGiftCardEligibility = (
  childSKU: PDPChildSKUInfo | null,
) => {
  if (!childSKU) {
    return false;
  } else {
    return childSKU.skuType === 'giftcard';
  }
};

export const checkEGiftCardEligibility = (childSKU: PDPChildSKUInfo | null) => {
  if (!childSKU) {
    return false;
  } else {
    return childSKU.skuType === 'eGiftcard';
  }
};

export const checkGiftCardEligibility = (
  childSKU: PDPChildSKUInfo | null,
): boolean => {
  if (!childSKU) {
    return false;
  } else if (
    checkPhysicalGiftCardEligibility(childSKU) ||
    checkEGiftCardEligibility(childSKU)
  ) {
    return true;
  } else {
    return false;
  }
};

export const filterSelectedSKU = (
  pdpProductInfoContent: PDPProductInfoContent,
  id: string,
) => {
  const selectedSKU =
    (pdpProductInfoContent?.product?.childSKUS?.length &&
      pdpProductInfoContent?.product?.childSKUS.find((el) => el.id === id)) ||
    null;

  return selectedSKU;
};

export const accessoriesFilterSelectedSKU = (
  pdpProductInfoContent: any,
  id: string,
) => {
  const selectedSKU =
    (pdpProductInfoContent?.product?.childSKUS?.length &&
      pdpProductInfoContent?.product?.childSKUS.find(
        (el: any) => el.id === id,
      )) ||
    null;

  return selectedSKU;
};

export const hasDiscontinuedProduct = (
  pdpProductInfoContent: PDPProductInfoContent,
) => {
  const selectedSKU =
    (pdpProductInfoContent?.product?.childSKUS?.length &&
      pdpProductInfoContent?.product?.childSKUS.every(
        (el) => el.status === Status.DISCONTINUED,
      )) ||
    false;

  return selectedSKU;
};

export const isNewProduct = (
  allSkusInfo: PDPAllSKUsInfo,
  productId: string,
): boolean => {
  if (isNotEmptyObject(allSkusInfo) && allSkusInfo[productId]?.isNew) {
    return true;
  } else {
    return false;
  }
};

export const isExclusiveProduct = (
  allChildSkus: PDPChildSKUInfo[],
  productId: string,
): boolean => {
  if (isNotEmptyArray(allChildSkus) || !isNotNullOrUndefined(productId)) {
    const selectedSku = allChildSkus?.find(
      (childSku: PDPChildSKUInfo) => childSku.id === productId,
    );
    if (selectedSku?.llExclusive === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getProductBadges = (
  allSkusInfo: PDPAllSKUsInfo,
  productId: string,
): [Badge] | null | undefined => {
  if (isNotEmptyObject(allSkusInfo)) {
    return allSkusInfo[productId]?.badges;
  }
};

export const getStockAvailability = (inventory: {
  isInStorePickupEligible: boolean;
  stockLevel: string;
  soldOutAllLocations: boolean;
}) => {
  const { isInStorePickupEligible, stockLevel, soldOutAllLocations } =
    inventory || {};
  return (
    isInStorePickupEligible &&
    stockLevel === 'Unavailable' &&
    !(soldOutAllLocations && isInStorePickupEligible)
  );
};

export const fetchInventoryDetails = async (
  storeId: string,
  dynSessConfNumber: string,
  internalAPI: string,
  allSkusInfo: [PDPChildSKUInfo],
) => {
  const skuIds = allSkusInfo?.map((item) => {
    return {
      qty: 1,
      skuId: item.storeSku ? item.storeSku : item.id,
    };
  });
  const endpoint = populateTemplateString(urlMapping.inventoryURL, {
    internalAPI,
  });
  const body = {
    storeId,
    type: 'store',
    skuIds,
    __dynSessConf: dynSessConfNumber,
  };

  const data = await makeFetchCall({
    options: {
      method: 'POST',
    },
    endpoint,
    body,
  });

  return data;
};
export const getQuantities = (): SelectOption[] => {
  return [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '11', value: 11 },
    { label: '12', value: 12 },
    { label: '13', value: 13 },
    { label: '14', value: 14 },
    { label: '15+', value: 15 },
  ];
};

export const isPersonalized = (message: string) => {
  if (message) {
    return true;
  }
  return false;
};

export const addToCart = async (
  endpoint: string,
  req: AddEGiftCardToCart | AddNonGiftItemToCart | AddPhysicalGiftCardToCart,
  _dynSessConf: string,
  dispatch: Function,
  encryptedRegistryId: string | boolean,
): Promise<AddToCartResponse> => {
  const request = {
    endpoint,
    body: {
      ...(encryptedRegistryId && { encryptedRegistryId }),
      items: [req],
      _dynSessConf,
      source: 'AddedToCart',
    },
    options: { method: 'POST' },
  };
  let response = await makeFetchCall(request);
  if (response?.stringSessionConfirmationNumber || response?.code === 409) {
    response = await refetch({
      response: response,
      request: request,
      dispatch,
    });
  }
  return response;
};

export const getShoppingList = async ({
  profileId,
  dynSessConfNumber,
  dispatch,
}: {
  profileId: string;
  dynSessConfNumber: string;
  dispatch: Function;
}) => {
  let getListRequest = {
    endpoint: urlMapping.getUserList,
    options: {
      method: 'POST',
    },
    body: {
      profileId: profileId,
      newGiftlistId: '',
      _dynSessConf: dynSessConfNumber,
    },
  };

  let listResponse = await makeFetchCall(getListRequest);
  if (listResponse?.stringSessionConfirmationNumber) {
    listResponse = await refetch({
      response: listResponse,
      request: getListRequest,
      dispatch,
    });
  }
  return listResponse;
};

export const getFormattedShoppingList = (data: ShoppingListResponse) => {
  try {
    const shoppingLists = data?.enrichedShoppingListResponseJSON?.shoppingLists;
    if (shoppingLists && shoppingLists?.length > 0) {
      const listResponse = shoppingLists?.map((ele) => {
        const { listThumbnail, shoppingListInfo } = ele;
        const { listName, listInfo } = listThumbnail;
        const { repositoryId, eventName } = shoppingListInfo;
        if (listName) {
          return {
            label: listName,
            value: repositoryId,
            type: listInfo?.split('|')[1].trim(),
          };
        } else if (eventName) {
          return {
            label: eventName,
            value: repositoryId,
            type: listInfo?.split('|')[1].trim(),
          };
        }
      });
      return listResponse as { label: string; value: string; type: string }[];
    }
    return [];
  } catch (error) {
    console.error('Error in making list::', error);
    return [];
  }
};

export const createShoppingList = async ({
  profileId,
  cartId,
  listName,
  listType,
  dynSessConfNumber,
  dispatch,
}: {
  profileId: string;
  cartId: string;
  listName: string;
  listType: string;
  dynSessConfNumber: string;
  dispatch: Function;
}) => {
  const createListRequest = {
    endpoint: urlMapping.createUserList,
    options: {
      method: 'POST',
    },
    body: {
      profileId: profileId,
      cartId: cartId,
      eventName: listName,
      isPublished: listType === 'public',
      _dynSessConf: dynSessConfNumber,
    },
  };
  let createListResponse = await makeFetchCall(createListRequest);
  if (createListResponse?.stringSessionConfirmationNumber) {
    createListResponse = await refetch({
      response: createListResponse,
      request: createListRequest,
      dispatch,
    });
  }
  return createListResponse;
};

export const addItemToList = async ({
  selectedListName,
  productId,
  productQty,
  selectedSkuId,
  dynSessConfNumber,
  dispatch,
}: {
  selectedListName: { value: string };
  productId: string;
  productQty: { [productId: string]: number };
  selectedSkuId: string;
  dynSessConfNumber: string;
  dispatch: Function;
}) => {
  const addRequest = {
    endpoint: urlMapping.addToUserList,
    options: {
      method: 'POST',
    },
    body: {
      giftlistId: selectedListName?.value,
      shoppingListAddItemInfo: [
        {
          productId: productId,
          quantity: productQty?.[productId],
          catalogRefId: selectedSkuId,
        },
      ],
      _dynSessConf: dynSessConfNumber,
    },
  };
  let addedResp = await makeFetchCall(addRequest);
  if (addedResp?.stringSessionConfirmationNumber) {
    addedResp = await refetch({
      response: addedResp,
      request: addRequest,
      dispatch,
    });
  }
  return addedResp;
};

/**
 *
 * @param minPrice
 * @param maxPrice
 * @returns
 */

export const getPrice = (minPrice: number, maxPrice?: number): string => {
  if (
    isNotNullOrUndefined(minPrice) &&
    isNotNullOrUndefined(maxPrice) &&
    minPrice !== maxPrice
  ) {
    return `${getFormattedPrice(minPrice)} - ${getFormattedPrice(maxPrice)}`;
  } else if (isNotNullOrUndefined(minPrice)) {
    return getFormattedPrice(minPrice);
  }
  return getFormattedPrice(0);
};

export const minArrayValue = function (array: number[]) {
  var min = 0;
  for (var i = 1; i < array.length; i++) {
    if (array[i] < array[min]) {
      min = i;
    }
  }
  return array[min];
};

export const maxArrayValue = function (array: number[]) {
  var max = 0;
  for (var i = 1; i < array.length; i++) {
    if (array[i] > array[max]) {
      max = i;
    }
  }
  return array[max];
};

export const getProductSkuPrice = (
  selectedSku?: {
    priceInfo?: {
      doorBuster?: boolean;
      doorBusterPrice?: number;
      bopisListPrice?: number;
      bopisSalePrice?: number;
      bopisSale?: boolean;
      sale?: boolean;
      salePrice?: number;
      special?: boolean;
      specialPrice?: number;
      listPrice?: number;
    };
  },
  deliveryMethod?: string,
) => {
  const priceInfo = selectedSku?.priceInfo || {};
  const {
    doorBuster,
    doorBusterPrice,
    bopisListPrice,
    bopisSalePrice,
    bopisSale,
    sale,
    salePrice,
    special,
    specialPrice,
    listPrice,
  } = priceInfo;

  let price = listPrice || 0;

  switch (true) {
    case !!doorBuster:
      if (deliveryMethod === 'store') {
        price = doorBusterPrice || price;
      } else if (deliveryMethod === 'shipItem') {
        price = listPrice || 0;
      }
      break;
    case !!bopisSale:
      price = bopisSalePrice || bopisListPrice || price;
      break;
    case !!sale:
      price = salePrice || price;
      break;
    case !!special:
      price = specialPrice || price;
      break;
    default:
      price = listPrice || 0;
      break;
  }

  return Math.ceil(price);
};

export const getRewardPoints = (
  tierInfo: {
    currentTier?: string;
    multiplier?: number;
  },
  rewardsConfig: { [key: string]: string },
) => {
  let rewardsMultiplier = 3;

  if (tierInfo?.currentTier) {
    const tierConfig = rewardsConfig[tierInfo.currentTier.toUpperCase()];
    if (tierConfig) {
      rewardsMultiplier = Number(tierConfig.slice(1));
    }
  }

  return rewardsMultiplier;
};

export const getWrapperRewardsPoint = ({
  tierInfo,
  selectedSku,
  rewardsConfig,
  deliveryMethod,
}: {
  tierInfo: {
    currentTier?: string;
    multiplier?: number;
  };
  selectedSku?: {
    priceInfo?: {
      doorBuster?: boolean;
      doorBusterPrice?: number;
      bopisListPrice?: number;
      bopisSalePrice?: number;
      bopisSale?: boolean;
      sale?: boolean;
      salePrice?: number;
      special?: boolean;
      specialPrice?: number;
      listPrice?: number;
    };
  };
  rewardsConfig: { [key: string]: string };
  deliveryMethod: string;
}) => {
  const rewardsPoint = '&lt;span class="bold"&gt; {point} &lt;/span&gt;';
  const point =
    getRewardPoints(tierInfo, rewardsConfig) *
    getProductSkuPrice(selectedSku, deliveryMethod);
  return populateTemplateString(rewardsPoint, { point });
};

export const chunkCarouselItem = (array: Array<any>, chunkSize: number) => {
  const result = [];
  const updatedResult = array.filter((item) => item?.listPriceRange?.length);
  for (let i = 0; i < updatedResult.length; i += chunkSize) {
    result.push(updatedResult.slice(i, i + chunkSize));
  }
  return result;
};

export const getWebContentsReq = async (
  productId: string,
  rcs: string,
  searchTerm?: string,
) => {
  if (!productId) return null;

  const isZrpWithSearch = productId === 'zrp' && searchTerm;
  const body = {
    pathKey: isZrpWithSearch ? 'zrpCarousels' : 'pdpCarousels',
    ...(isZrpWithSearch ? { searchTerm } : { productId }),
    ...(rcs?.length > 0 && { rcs }),
  };

  const payload = {
    endpoint: urlMapping.getWebContents,
    options: { method: 'POST' },
    body,
  };

  let response = await makeFetchCall(payload);
  let retryCount = 0;

  if (response?.status === 'success') {
    const itemObj = findObject(response?.content, 'PageContent-ProductRecs');
    const updatedRcs = itemObj?.[0]?.rcs;

    if (!rcs && updatedRcs) {
      const expiryTime = new Date().getTime() + 1e3 * 364 * 60 * 60 * 24; // One year from now
      const newRcs = JSON.stringify({ expires: expiryTime, value: updatedRcs });
      localStorage.setItem('rr_rcs', newRcs);

      payload.body.rcs = updatedRcs;
      response = await makeFetchCall(payload);
    }
  } else if (response?.code === 409 && retryCount < 2) {
    while (retryCount < 2) {
      retryCount++;
      response = await makeFetchCall(payload);
      if (response?.status === 'success') break;
    }
  } else {
    console.error(
      'Error in getWebContents API in PDPCarousel page-',
      response?.error_description,
    );
    return null;
  }

  return response;
};

export const findObject = (obj: any, targetValue: string): any[] => {
  let result: any[] = [];

  if (obj && obj['@type'] === targetValue) {
    result.push(obj);
  }

  if (Array.isArray(obj)) {
    for (const item of obj) {
      result = result.concat(findObject(item, targetValue));
    }
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      result = result.concat(findObject(obj[key], targetValue));
    }
  }

  return result;
};

export const getRegistryList = async ({
  dynSessConfNumber,
  dispatch,
}: {
  dynSessConfNumber: string;
  dispatch: Function;
}) => {
  const reqOptions = {
    endpoint: urlMapping.profileRegistry,
    options: {
      method: 'POST',
    },
    body: {
      _dynSessConf: dynSessConfNumber,
    },
  };

  let listApiResp = await makeFetchCall(reqOptions);
  if (listApiResp?.stringSessionConfirmationNumber) {
    listApiResp = await refetch({
      response: listApiResp,
      request: reqOptions,
      dispatch,
    });
  }
  return listApiResp;
};

export const addRegistries = async ({
  encryptedRegistryId,
  repositoryId,
  catalogRefId,
  productId,
  selectedItemQty,
  isPersonalized,
  message,
  dynSessConfNumber,
  dispatch,
}: {
  encryptedRegistryId: string;
  repositoryId: string;
  catalogRefId: string;
  productId: string;
  selectedItemQty: number;
  isPersonalized: boolean;
  message: string;
  dynSessConfNumber: string;
  dispatch: Function;
}) => {
  const reqOptions = {
    endpoint: urlMapping.addItemToRegistry,
    options: {
      method: 'POST',
    },
    body: {
      encryptedRegistryId,
      registryId: repositoryId,
      shoppingListAddItemInfo: [
        {
          catalogRefId,
          productId,
          quantity: selectedItemQty,
          personalized: isPersonalized,
          ...(isPersonalized ? { personalizationMessage: message } : {}),
        },
      ],
      _dynSessConf: dynSessConfNumber,
    },
  };
  let addRegistryApiResp = await makeFetchCall(reqOptions);
  if (addRegistryApiResp?.stringSessionConfirmationNumber) {
    addRegistryApiResp = await refetch({
      response: addRegistryApiResp,
      request: reqOptions,
      dispatch,
    });
  }

  return addRegistryApiResp;
};

export const shareProduct = async ({
  nameFrom,
  emailFrom,
  personalMessage,
  namesTo,
  emailsTo,
  ids,
  displayName,
  itemNumber,
  productId,
  imageId,
  emailCraft,
  isBot,
  dispatch,
  _dynSessConf,
}: PayloadShareProduct) => {
  let requestPayload = {
    endpoint: urlMapping.postProductData,
    options: {
      method: 'POST',
    },
    body: {
      nameFrom,
      emailFrom,
      personalMessage,
      namesTo,
      emailsTo,
      ids,
      displayName,
      itemNumber,
      productId,
      imageId,
      emailCraft,
      isBot,
      _dynSessConf,
    },
  };

  let response = await makeFetchCall(requestPayload);

  if (response?.stringSessionConfirmationNumber) {
    response = await refetch({
      response: response,
      request: requestPayload,
      dispatch,
    });
  }
  return response;
};

export const prepareGuestCheckout = async ({
  paypalCheckout,
  isPaypalFromCheckout,
  _dynSessConf,
  dispatch,
}: {
  paypalCheckout: boolean;
  isPaypalFromCheckout: boolean;
  _dynSessConf: number;
  dispatch: Function;
}) => {
  let requestPayload = {
    endpoint: urlMapping.prepareGuestCheckout,
    options: {
      method: 'POST',
    },
    headers: { 'Content-Type': 'application/json' },
    body: {
      paypalCheckout,
      isPaypalFromCheckout,
      _dynSessConf,
    },
  };

  let response = await makeFetchCall(requestPayload);

  if (response?.code === 401) {
    response = await refetch({
      response: response,
      request: requestPayload,
      dispatch,
    });
  }
  return response;
};

export const applyPaypalCheckout = async ({
  useRegistryAddress,
  isPaypalFromCheckout,
  _dynSessConf,
  dispatch,
}: {
  useRegistryAddress: boolean;
  isPaypalFromCheckout: boolean;
  _dynSessConf: number;
  dispatch: Function;
}) => {
  const requestPayload = {
    endpoint: urlMapping.applyPayPalCheckout,
    options: {
      method: 'POST',
    },
    headers: { 'Content-Type': 'application/json' },
    body: {
      useRegistryAddress,
      isPaypalFromCheckout,
      _dynSessConf,
    },
  };

  let response = await makeFetchCall(requestPayload);

  if (response?.code === 401) {
    response = await refetch({
      response: response,
      request: requestPayload,
      dispatch,
    });
  }
  return response;
};

export const algonomyScript = (
  algonomyPropsData: any,
  algonomyAPIKey: string,
  algonomyBaseURL: string,
  dynSessConfNumber: string,
) => {
  const { baseUrl } = configuration;
  const script = document.createElement('script');
  script.src = '/react/scripts/algonomy/p13n.js';
  script.async = true;
  script.onload = () => {
    if (algonomyPropsData?.length > 0) {
      let r3Common = new (window as any).r3_common();
      r3Common.setApiKey(algonomyAPIKey);
      r3Common.setBaseUrl(algonomyBaseURL);
      r3Common.setSessionId(dynSessConfNumber);
      r3Common.addPlacementType('pid');
      r3Common.setClickthruServer(baseUrl);

      try {
        algonomyPropsData.map((propsData: any) => {
          r3Common.addPlacementType(propsData?.placementId);
        });
      } catch (error) {
        console.error('🚀 ~ useEffect ~ error:--', error);
      }
      (window as any).r3();
    }
  };
  document.body.appendChild(script);
};
export const getProductPrice = ({
  data,
  selectedGiftCardDetails,
}: {
  data: PDPPriceInfo;
  selectedGiftCardDetails?: { amount: string | number };
}) => {
  const {
    onSale = false,
    gsa = false,
    hasGSAPrice,
    saleMinPrice,
    saleMaxPrice,
    maxPrice,
    minPrice,
    special,
    specialPrice,
    listPrice,
    doorBusterPrice,
    salePrice,
    sale,
    bopisSale,
    bopisSalePrice,
    doorBuster,
    isGiftcard,
    giftcardPriceRange,
  } = data || {};

  let prices = new Array<number>();
  let listPrices = new Array<number>();

  const isGSA = gsa || hasGSAPrice;
  const isSale = onSale || sale;
  const isGiftCard = isGiftcard;
  const amountGiftCard = selectedGiftCardDetails?.amount
    ? selectedGiftCardDetails?.amount
    : giftcardPriceRange;

  if (listPrice || minPrice || maxPrice) {
    listPrice && listPrices.push(listPrice);
    minPrice && listPrices.push(minPrice);
    maxPrice && listPrices.push(maxPrice);
  }
  if (isSale) {
    sale && salePrice && prices.push(salePrice);
    saleMinPrice && prices.push(saleMinPrice);
    saleMaxPrice && prices.push(saleMaxPrice);
  } else if (isGSA) {
    gsa && specialPrice && prices.push(specialPrice);
    saleMinPrice && prices.push(saleMinPrice);
    saleMaxPrice && prices.push(saleMaxPrice);
  } else if (bopisSale) {
    bopisSalePrice && prices.push(bopisSalePrice);
  } else if (doorBuster) {
    doorBusterPrice && prices.push(doorBusterPrice);
  }
  if (special && specialPrice) {
    prices.push(specialPrice);
  }

  let minimumPrice = minArrayValue(prices);
  let maximumPrice = maxArrayValue(prices);
  let minListPrice = minArrayValue(listPrices);
  let maxListPrice = maxArrayValue(listPrices);

  if (isGiftCard) {
    return { value: amountGiftCard, color: 'black' };
  } else if (isSale && !isGSA) {
    return { value: getPrice(minimumPrice, maximumPrice), color: 'red' };
  } else if (isGSA) {
    return { value: getPrice(minimumPrice, maximumPrice), color: 'green' };
  }
  return { value: getPrice(minListPrice, maxListPrice), color: 'black' };
};

let addlSaving = false;
let addlSaveSalePrice = 0;

const setaddtlStoreDisc = function (priceInfo: any) {
  addlSaving = priceInfo.addlSaving;
  addlSaveSalePrice = priceInfo.addlSaveSalePrice;
};

export const getPriceRange = (
  skus: any,
  dropdownType: string,
  dropdownValue: string,
) => {
  var Prices = new Array();
  var listPrices = new Array();
  var minPrice, maxPrice, minListPrice, maxListPrice;
  var onSale = false;
  var hasGSAPrice = false;
  var hasOnlyGSAPrice = true;
  var hasSpecialPrice = false;
  var hasOnlySpecialPrice = true;
  var isDoorbuster = false;
  for (var i = 0; i < skus.length; i++) {
    var sku = skus[i];
    if (sku[dropdownType] === dropdownValue) {
      if (!(sku.status === 'INACTIVE') || skus.length == 1) {
        var listPrice = Number(sku.priceInfo.listPrice);
        if (Number.isNaN(listPrice)) {
          listPrice = 0;
        }

        var specialPrice = Number(sku.priceInfo.specialPrice);
        if (Number.isNaN(specialPrice)) {
          specialPrice = 0;
        }

        var doorBusterPrice = Number(sku.priceInfo.doorBusterPrice);
        if (Number.isNaN(doorBusterPrice)) {
          doorBusterPrice = 0;
        }

        isDoorbuster = doorBusterPrice > 0;
        var isSpecialPrice = sku.priceInfo.special;
        var isGSAPrice = sku.priceInfo.gsa || sku.isGsaPrice; // this flag is available when ading gsa from atc modal
        var isAddtlStoreDiscAllowed = sku.priceInfo.addlSaving;

        if (sku.priceInfo.sale || sku.priceInfo.onSale) {
          onSale = true;
          hasOnlyGSAPrice = hasOnlySpecialPrice = false;
          if (isAddtlStoreDiscAllowed) {
            setaddtlStoreDisc(sku.priceInfo);
          }
          Prices.push(sku.priceInfo.salePrice);
        } else if (isSpecialPrice) {
          hasSpecialPrice = true;
          hasOnlyGSAPrice = false;
          Prices.push(specialPrice);
        } else if (isGSAPrice) {
          hasGSAPrice = true;
          hasOnlySpecialPrice = false;
          if (sku.isGsaPrice) {
            Prices.push(listPrice);
          } else {
            Prices.push(specialPrice);
          }
        } else if (isDoorbuster) {
          hasOnlyGSAPrice = hasOnlySpecialPrice = false;
          if (isAddtlStoreDiscAllowed) {
            setaddtlStoreDisc(sku.priceInfo);
          }
          Prices.push(doorBusterPrice);
        } else if (isAddtlStoreDiscAllowed) {
          setaddtlStoreDisc(sku.priceInfo);
          Prices.push(listPrice);
        } else {
          hasOnlyGSAPrice = hasOnlySpecialPrice = false;
          Prices.push(listPrice);
        }

        if (listPrice > 0) {
          listPrices.push(listPrice);
        }
      }
    }
  }
  minPrice = minArrayValue(Prices);
  maxPrice = maxArrayValue(Prices);
  minListPrice = minArrayValue(listPrices);
  maxListPrice = maxArrayValue(listPrices);

  return {
    minPrice: minPrice,
    maxPrice: maxPrice,
    onSale: onSale,
    isDoorbuster: isDoorbuster,
    addlSaving: addlSaving,
    addlSaveSalePrice: addlSaveSalePrice,
    hasGSAPrice: hasGSAPrice,
    hasOnlyGSAPrice: hasOnlyGSAPrice,
    hasListPriceRange: maxListPrice !== minListPrice,
    hasSpecialPrice: hasSpecialPrice,
    hasOnlySpecialPrice: hasOnlySpecialPrice,
    minListPrice: minListPrice,
    maxListPrice: maxListPrice,
  };
};

export const getDropdownPriceRange = (priceInfo: any) => {
  const {
    minPrice,
    maxPrice,
    onSale,
    hasGSAPrice,
    minListPrice,
    maxListPrice,
  } = priceInfo.data;

  const isGSA = hasGSAPrice;
  const isSale = onSale;
  let priceRange = '';
  let regPriceRange = '';
  if (minListPrice && maxListPrice && minListPrice === maxListPrice) {
    priceRange = `${getFormattedPrice(minPrice)}`;
    regPriceRange = `${getFormattedPrice(minListPrice)}`;
  } else {
    priceRange = getPrice(minPrice, maxPrice);
    // eslint-disable-next-line no-unused-vars
    regPriceRange = getPrice(minListPrice, maxListPrice);
  }
  if (isSale) {
    return { value: priceRange, color: 'red' };
  } else if (isGSA) {
    return { value: priceRange, color: 'green' };
  } else {
    return { value: priceRange, color: 'black' };
  }
};

export const isPresellDateInFuture = (presellDate: string) => {
  if (!presellDate) return false;

  const currentDate = new Date();
  const itemPresellDate = new Date(presellDate);

  // Check if the presellDate is later than the current date
  return itemPresellDate > currentDate;
};

export const getCanonicalUrl = (metaData: { [key: string]: string | any }) => {
  let canonicalUrl = metaData?.canonicalURL?.endsWith('/json/')
    ? metaData?.canonicalURL?.replace('/json/', '')
    : metaData?.canonicalURL;
  canonicalUrl =
    metaData?.canonicalURL !== null
      ? `${metaData?.siteUrl}${canonicalUrl}`
      : metaData?.siteUrl;
  return canonicalUrl;
};
