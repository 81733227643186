import { localStorageRetainKeys } from '@next-app/constants/constants';

export const clearLocalStorage = function () {
  const retainedValues: { [key: string]: string | null } = {};
  localStorageRetainKeys.forEach((key: string) => {
    retainedValues[key] = localStorage.getItem(key);
  });

  localStorage.clear();

  Object.keys(retainedValues).forEach((key: string) => {
    localStorage.setItem(key, retainedValues[key] || '');
  });
};
