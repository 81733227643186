import { createSlice } from '@reduxjs/toolkit';
import { LoadCheckoutResponse } from './Checkout.interface';

interface CheckoutInitState {
  checkoutLoadDetails: LoadCheckoutResponse | {};
}

const checkoutState = {
  checkoutLoadDetails: {},
} satisfies CheckoutInitState as CheckoutInitState;

export const CheckoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutState,
  reducers: {
    setLoadCheckoutDetails(state, action) {
      state.checkoutLoadDetails = action.payload;
    },
  },
});

export const { setLoadCheckoutDetails } = CheckoutSlice.actions;

export default CheckoutSlice.reducer;
