import { configureStore, createSlice } from '@reduxjs/toolkit';
import { ProductSlice } from './features/PDP/ProductSlice';
import { MasterProduct } from './features/PDP/MasterProduct';
import { CheckoutSlice } from './features/checkout/CheckoutSlice';

interface InitState {
  sessionNumber: any;
  init: any;
  sessionInfo: any;
  headerFooter: any;
  deviceType: string;
  seoData: any;
  productRecsData: any;
}

const initialState = {
  sessionNumber: {},
  init: {},
  sessionInfo: {},
  headerFooter: {},
  deviceType: '',
  seoData: [],
  productRecsData: {},
} satisfies InitState as InitState;

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setData(state: any, action: any) {
      state.sessionNumber = action.payload.sessionNumber;
      state.init = action.payload.init;
      state.sessionInfo = action.payload.sessionInfo;
      state.headerFooter = action.payload.headerFooter;
      state.deviceType = action.payload.deviceType;
      state.productRecsData = action.payload.productRecsData;
    },
    updateSelectedStore(state: any, action) {
      state.sessionInfo.sessionInfo.profile.selectedStore = action.payload;
    },
    updateDynSessConf(state: any, action: any) {
      state.sessionInfo.sessionInfo.dynSess.dynSessConfNumber = action.payload;
    },
    updateCartCount(state: any, action: any) {
      state.sessionInfo.sessionInfo.cartInfo.cartQty = action.payload;
    },
    setSeo(state: any, action: any) {
      state.seoData = action.payload.seoData;
    },
  },
});

export const {
  setData,
  updateCartCount,
  setSeo,
  updateDynSessConf,
  updateSelectedStore,
}: any = initSlice.actions;
export default initSlice.reducer;

export const makeStore = () => {
  return configureStore({
    reducer: {
      [initSlice.name]: initSlice.reducer,
      [ProductSlice.name]: ProductSlice.reducer,
      [MasterProduct.name]: MasterProduct.reducer,
      [CheckoutSlice.name]: CheckoutSlice.reducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
