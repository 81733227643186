const urlMapping = {
  sessionConfirmationNumberUrl:
    '/rest/model/atg/rest/SessionConfirmationActor/getStringSessionConfirmationNumber',
  headerFooterUrl:
    '/rest/model/atg/userprofiling/ProfileActor/headerFooterContent',
  sessionInformationUrl:
    '/rest/model/atg/userprofiling/ProfileActor/sessionInfo',
  findStoreUrl: '/int/findStoresByZip',
  applyStoreUrl:
    '/rest/model/ll/locations/LLStoreLocatorActor/applySelectedStore',
  tomtomUrl: 'https://api.tomtom.com/search/2/structuredGeocode.json',
  searchSuggestedNttUrl: '/rest/model/ll/typeahead/TypeAheadActor/suggest/Ntt',
  searchRedirectUrl: '/search?Ntt=',
  searchFlyoutUrl: '/rest/model/ll/typeahead/TypeAheadActor/flyout',
  initUrl: '/rest/model/ll/util/UtilsActor/init',
  homePage: '/home/json/',
  gsaHomePage: '/home/json/gsa/',
  fetchRecsProductInfo:
    '/rest/model/ll/commerce/recommendations/RecommendationsActor/fetchRecsProductInfo',
  stayConnectedUrl: '/rest/model/ll/commerce/contact/LLContactUsActor/signUp',
  loginUserUrl: '/rest/model/atg/userprofiling/ProfileActor/login',
  forgotResetCodUrl:
    '/rest/model/atg/userprofiling/ProfileActor/forgotPasswordResetCode',
  forgotValidatePwdResetCode:
    '/rest/model/atg/userprofiling/ProfileActor/validatePwdResetCode',
  forgotResetPasswordB2C:
    '/rest/model/atg/userprofiling/ProfileActor/resetPasswordB2C',
  createAccountUserUrl: '/rest/model/atg/userprofiling/ProfileActor/createB2C',
  GSAUserUrl: '/rest/model/atg/userprofiling/ProfileActor/createGSA',
  GSARegistrationContinueUrl:
    '/rest/model/atg/userprofiling/ProfileActor/continueGSARegistration',
  AnalyticsDTM: '/ext/logDTMSuccessData',
  login: '/rest/model/atg/userprofiling/ProfileActor/login',
  logout: '/rest/model/atg/userprofiling/ProfileActor/logout',
  refreshToken:
    'https://qa-auth.llmhq.com/auth/realms/qa/protocol/openid-connect/token',
  getAllSEOData: '/int/getAllSEOData',
  getMessageValuesforKeys:
    '/rest/model/ll/util/UtilsActor/getMessageValuesForKeys',
  loadGiftCardBalanceUrl:
    '/rest/model/ll/auxPage/AuxPageActor/loadGiftCardBalance',
  trackConversionUri: '/ext/trackConversion',
  cartridges: '/react-library/cartridges/json',
  inventoryURL: '{internalAPI}/int/getInventory',
  // add to list api url
  getUserList: '/rest/model/atg/commerce/gifts/GiftlistActor/profileGiftlists',
  createUserList: '/rest/model/atg/commerce/gifts/GiftlistActor/createGiftlist',
  addToUserList:
    '/rest/model/atg/commerce/gifts/GiftlistActor/addItemToGiftlist',

  // PDP endpoints
  getCarouselData: '/assembler?assemblerContentCollection',
  reviewSortBy: '/rest/model/vsg/review/ReviewActor/reviewsRange',
  reviewHelpful:
    '/rest/model/vsg/review/ReviewActor/createReviewHelpfulnessEntry',
  profileRegistry:
    '/rest/model/atg/commerce/gifts/GiftlistActor/profileRegistries',
  addItemToRegistry:
    '/rest/model/atg/commerce/gifts/GiftlistActor/addItemToRegistry',
  pdpContentAPI: '/data/product/{pageType}/{productId}/json/{gsa}/',
  facebookURLPrefix: 'https://www.facebook.com/sharer/sharer.php?u=https%3A//',
  pinterestURLPrefix: 'https://pinterest.com/pin/create/button/?url=',
  postProductData:
    '/rest/model/ll/util/mail/product/LLProductSendEmailActor/productSendEmail',
  getWebContents: '/rest/model/ll/util/UtilsActor/getWebContents',
  giftCard:
    '/rest/model/atg/commerce/order/purchase/CartModifierActor/addGiftItemToOrder',
  productCart:
    '/rest/model/atg/commerce/order/purchase/CartModifierActor/addItemToOrder',
  currentPromotion: '/coupons/legal/current-promotions',
  createRegistry: '/lists-and-registries/registry-form/',
  oftCoupon:
    '/rest/model/atg/commerce/promotion/CouponActor/getOnTheFlyCouponCode',
  fsDeleteSession: '/ext/fullstory/deleteSession',
  prepareGuestCheckout:
    '/rest/model/atg/commerce/order/purchase/PurchaseActor/prepareGuestCheckout',
  applyPayPalCheckout:
    '/rest/model/atg/commerce/order/purchase/PurchaseActor/applyPayPalCheckout',
  fetchApplePayData:
    '/rest/model/ll/commerce/payment/applepay/ApplePayActor/getApplePayPaymentRequest',
  applePayUpdateShippingContact:
    '/rest/model/ll/commerce/payment/applepay/ApplePayActor/updateShippingContact',
  applePayupdateShippingMethod:
    '/rest/model/ll/commerce/payment/applepay/ApplePayActor/updateShippingMethod',
  applyApplePay:
    '/rest/model/ll/commerce/payment/applepay/ApplePayActor/applyApplePay',
  cancelApplePay:
    '/rest/model/ll/commerce/payment/applepay/ApplePayActor/cancelApplePay',
  applyGooglePay:
    '/rest/model/ll/commerce/payment/googlepay/GooglePayActor/applyGooglePay',
  productsCategory: '/products/{category}/json',

  // checkout
  loadCheckoutDetails:
    '/rest/model/atg/commerce/order/purchase/PurchaseActor/loadCheckoutDetails',
};

export default urlMapping;
